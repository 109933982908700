.modalWrapper {
    max-height: 85vh;
    overflow: auto;
    width: calc(100% + 10px);
    padding-right: 10px;
    box-sizing: border-box;
}
.spaceBetween {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.comboTourBusesContainer {
    max-height: 150px;
    padding-right: 5px;
    overflow-y: auto;
}
.comboTourBusFormItem {
    display: flex;
    margin-bottom: 12px;
    gap: 8px;
    align-items: center;
}
.formItemGroup {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px;
    width: 100%;
}
.comboSection_title {
    font-size: 17px;
    font-weight: 600;
}
@media screen and (max-width: 1145px){
    .extraServiceTab {
        min-height: 300px !important;
    }
}