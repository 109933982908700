.collapseSection {
    max-height: 0; /* Initially hidden */
    overflow: hidden;
    opacity: 0; /* Make it invisible */
    transition: transform  0.5s ease, opacity 0.3s ease; /* Add transition */
}
.collapseSection.open{
    max-height: unset; /* Initially hidden */
    opacity: 1;  /* Fade in */
  transform: translateY(0); 
}