.small-row .ant-table-cell {
    padding: 4px 8px !important; /* Giảm padding để giảm chiều cao */
    line-height: 1.2; /* Điều chỉnh khoảng cách chữ */
  }
  .highlight-row .ant-table-cell {
    padding: 4px 8px !important; /* Giảm padding để giảm chiều cao */
    line-height: 1.2; /* Điều chỉnh khoảng cách chữ */
  }

  .highlight-row {
    background-color: #adadad !important; /* Màu vàng nhạt */
  }