.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}
.justifyBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.billPrice {
    font-size: 20px;
}
.ticketSection {
    display: flex;
    min-height: 700px;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
/* ticket card */
.ticketCard {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: clamp(500px, 100%, 850px);
    width: 410px;
    position: relative;
    z-index: 2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 2px solid #005BA5;
    background-color: white;
}
.actionButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
}