.agentsContainer {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    /*z-index: 6; */
}
.agentsWrapper {
    flex: 1;
}
.container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    width: clamp(16rem, 90vw, 70rem);
    justify-content: space-between;
    gap: 13px;
    background-color: transparent;
    flex-wrap: wrap;
}
.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    max-width: 600px;
    min-width: 400px;
    flex: 1;
    margin-inline: auto;
}
.listContainer_header {
    background-color: rgba(227, 239, 252, 0.699);
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
} 
.listWrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.listWrapper_List {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 10px 0 16px;
}
.listContainer_footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    width: 100%;
}
.textWithIcon {
    display: flex;
    gap: 8px;
    align-items: center;
}
.btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #D9D9D9;
    flex-basis: 40px;
    padding-block: 20px;
    gap: 20px;
}
.searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 13px 16px;
    width: 100%;
    min-height: 58px;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.agentItem, 
.agentAccountItem {
    display: flex;
    background: #FFFFFF;
    justify-content: space-between;
    opacity: 0.8;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}
.agentItem:hover,
.agentAccountItem:hover {
    background-color: #f3f9ffb9;
}
.agentItem.agentSelected {
    background-color: #F3F9FF;
    border: 1px solid #7D91BC;
}
.agentItem_wrapper,
.agentAccountItem_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    justify-content: space-between;
    gap: 16px;
}

.agentItem.isErased,
.agentAccountItem.isErased {
    position: relative;
}
.agentItem.isErased .agentItem_wrapper,
.agentItem.isErased .btnContainer,
.agentAccountItem.isErased .agentAccountItem_wrapper,
.agentAccountItem.isErased .btnContainer {
    opacity: .6;
}
.agentItem.isErased::before,
.agentAccountItem.isErased::before {
    --f: 10px; /* control the folded part*/
    --r: 5px; /* control the ribbon shape */
    --t: 10px; 
    content: 'Deleted';
    position: absolute;
    color: white;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.747);
    inset: var(--t) calc(-1*var(--f)) calc(100% - 45px) auto;
    padding: 2px 10px var(--f) calc(10px + var(--r));
    clip-path: 
        polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    z-index: 2;
}
.agentItem.isErased::after ,
.agentAccountItem.isErased::after {
    content: '';
    position: absolute;
    border: 1px solid black;
    inset: 0;
    background-color: rgb(90, 90, 90);
    opacity: .2;
    z-index: 1;
}
/* discount */
.comboTourItem {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #0000003d;
    box-sizing: border-box;
    cursor: pointer;
}
.comboTourItem:hover {
    border: 1px solid #000000d2;
    transition: all 200ms ease-in-out;
}
.formItemGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}