.ticketOperatorContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    /* width: clamp(16rem, 96%, 110rem); */
    /* margin-inline: auto; */
}

.ticketOperatorContainer_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.title {
    font-size: 22px;
    font-weight: 500;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px 14px;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.filterItemContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.table {
    width: 100%;
}

.collectionFee_modal_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    padding-inline: 4px;
    column-gap: 20px;
}

.collectionFee_modal_methodForm_container {
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 12px;
}

.formItemGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.collapseSection {
    max-height: 0;
    /* Initially hidden */
    overflow: hidden;
    opacity: 0;
    /* Make it invisible */
    transition: transform 0.5s ease, opacity 0.3s ease;
    /* Add transition */
}

.collapseSection.open {
    max-height: unset;
    /* Initially hidden */
    opacity: 1;
    /* Fade in */
    transform: translateY(0);
}