.toursContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* width: 600px; */
    margin-inline: auto;
    padding-block: 23px;
    max-height: 100%;
    max-width: 100%;
}
.tours {
    display: grid;
    gap: 16px ;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    max-height: calc(100dvh - 90px - 58px - 78px);
    overflow-y: auto;
    /* scroll-snap-type: y mandatory; */
    padding-block: 5px;
    scroll-behavior: smooth;
}
.tours::-webkit-scrollbar,
.tours::-webkit-scrollbar-track,
.tours::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
}
.tours::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.scrollAble::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.scrollAble::-webkit-scrollbar
{
    width: 4px;
    height: 4px;
    background-color: #F5F5F5 !important;
}

.scrollAble::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}
/* .tours > * {
    scroll-snap-align: end;
} */
.tour {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* max-height: 235px; */
    height: 180px;
    cursor: pointer;
    /* min-height: 150px; */
    border: 2px solid transparent;
    width: 100%;
    max-width: 800px;
    margin-inline: auto;
}
.tour.bookedTour {
    border: 2px solid red;
}
.imgContainer {
    /* height: 100%; */
    width: 45%;
    overflow: hidden;
}
.tourImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 300ms ease-in-out;
}
.tour:hover .tourImg {
    transform: scale(1.1);
}
.inforWrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    flex: 1;
    width: 55%;
}
.inforWrapper .buttonTourDetail {
    display: inline-block;
}
.tourInfor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2px 10px;
    flex-wrap: wrap;
}
.tourPrice {
    font-size: 16px;
    text-align: right;
    color: rgba(113, 125, 150, 1);
    font-weight: 600;
}
.buttonTourDetail {
    font-weight: 600;
    background-color: rgba(45, 54, 72, 1);
    color: white;
    min-height: 40px;
    border: none;
}
.buttonTourDetail:hover {
    color: white !important;
    background-color: rgba(72, 90, 126, 0.945);
}

@media only screen and (max-width: 770px) {
    .tourInfor {
        flex-direction: column;
        gap: 5px;
        align-items: flex-end;
    }
}
@media only screen and (max-width: 520px) {
    .tours {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}
