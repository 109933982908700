.wrapper {
    gap: 15px;
    flex-wrap: wrap;
    max-height: 100%;
    min-height: 750px;
    height: 100%;
    margin-inline: auto;
    display: flex;
    padding-inline: clamp(1rem, 5%, 2rem);
}
.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    /* flex: 1; */
}
.listContainer_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 0;
}
.detailContainer {
    flex: 1;
    width: 100%;
}
.detailWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    padding: 12px 24px;
    flex-wrap: wrap;
    background-color: white;
}
.detailWrapper_column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.detailWrapper_item {
    flex: 1;
    width: 100%;
    background: #FFFFFF;
/* Neutral/5 */
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    min-height: 260px;
    overflow: hidden;
}
/* detail view */
.detailView {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* max-height: 100%; */
    display: flex;
    flex-direction: column;
}
.detailView_title {
    background-color: rgba(227, 239, 252, 0.699);
    /* height: 0px; */
    display: flex;
    padding: 8px 16px;
    width: 100%;
}
.detailView_footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #D9D9D9;
    padding: 8px 12px;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detailView_wrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.scrollContainer {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 24px;
}
.customerScrollContainer {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 8px 8px 12px  ;
}

.listWrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.listWrapper_List {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 24px;
}
.listContainer_footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    width: 100%;
}
.groupRunningDate {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 12px 24px 8px;
    align-items: center;
    display: flex;
    gap: 4px;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.36); */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    z-index: 5;
}
/* group item */
.groupItem {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    min-height: 90px;
}
.groupItem:not(.disabled):hover {
    background-color: #fff7e65b;
}
.groupItem:not(.disabled).selected {
    background-color: #FFF7E6;
    border: 1px solid #005BA5;
}
.groupItem.disabled {
    background-color: #adadad5b;
}
.groupItem.disabled.selected {
    border: 1px solid #005BA5;
}
.groupItem_Wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.groupName {
    margin-block: 0 !important;
}
.groupItem.disabled .groupName {
    color: #8c8c8c;
}
.groupItem_Info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.journeyItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}   
.journeyItem_info {
    display: flex;
    justify-content: space-between;
    gap: 6px;
}
.journeyItem_info .journeyItem_info_item:nth-of-type(2) {
    text-align: right;
}
.journeyItem_info_item {
    font-size: 14px;
    color: #000000E0;
}
.issueItem {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--neutral-5, #D9D9D9);
    margin-bottom: 8px;
}
.issueItem.notPaid{
    border: 2px solid var(--neutral-5, #ef3333);
}
.issueItem_wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.issueItem_issueName {
    font-size: 20px;
    line-height: 28px;
    white-space: nowrap;
}
.issueItem_item {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.issueItem_btnGroup {
    display: flex;
    padding: 12px 16px 12px 21px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-top: 1px solid var(--neutral-5, #D9D9D9);
}
.issueItem_btns {
    display: flex;
    gap: 10px;
    align-items: center;
}
.issueItem_confirmBtn {
    background-color: #52C41A;
    color: white;
}
.issueItem_confirmBtn:not(:disabled):hover {
    color: white !important;
    border-color: #55b128 !important;
    background-color: #6ab842;
}
.issueItem_confirmBtn:not(:disabled):active {
    color: white !important;
    border-color: #55b128 !important;
}
.issueItem_confirmBtn:not(:disabled):focus-visible {
    outline: 4px solid #52C41A !important;
}
@media screen and (max-width: 1500px) {
    .wrapper {
        justify-content: center;
    }
    .listContainer_group {
        min-height: 330px;
        max-height: 330px;
        width: clamp(300px, 100%, 65rem);
        min-width: 300px !important;
    }
    .listWrapper_List_group {
        flex-direction: row;
    }
}
@media screen and (max-width: 768px) {
    .wrapper {
        height: max-content;
        max-height: unset;
        padding-inline: 0;
    }
    .issuesContainer {
        max-height: 200px;
    }
}
@media screen and (max-width: 500px) {
    .wrapper {
        padding: 2.5rem 0 0;
    }
    .listContainer_header {
        padding-bottom: 8px;
    }
    .listContainer_header,
    .groupRunningDate,
    .detailWrapper {
        padding-inline: 8px;
        overflow-x: auto;
    }

}