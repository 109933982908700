.bookingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    min-height: calc(100dvh - 90px);
    /*z-index: 6; */
}
.tourTicketWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 1;
    column-gap: 10px;
    row-gap: 20px;
    flex-wrap: wrap;
    height: fit-content;
    max-height: 850px;
    flex: 1;
    width: 100%;
}
.customerContainer {
    width: clamp(20rem, 100%, 680px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    position: relative;
    z-index: 3;
    max-height: 850px;
}
.progressContainer {
    background-color: white;
    padding: 16px;
    width: clamp(20rem, 95vw, 950px);
    border-radius: 10px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ticketsContainer {
    width: 100%;
    flex-direction: column;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    flex: 1;
    min-height: 400px;
    /* max-height: calc(100dvh - 300px); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ticketsWrapper {
    padding: 0;
    background-color: white;
    position: relative;
    z-index: 1;
    flex: 1;
}
.ticketsWrapper_Header {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 8px;
    background-color: #F3F9FF;
    gap: 10px;
    flex-wrap: wrap;
}
.totalWrapper {
    padding: 8px 32px 24px;
    background-color: rgba(241, 241, 241, 1);
    z-index: 2;
}
.tour {
    display: flex;
    flex-direction: column;
}
.tourInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    gap: 8px;
}
.ticketInfo {
    align-items: center;
    display: flex;
    gap: 10px;
    min-width: 150px;
    align-self: flex-end;
}
.checkoutBtnContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tourTickets, .tourList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.tourList {
    gap: 10px;
    max-height: 630px;
    height: calc(100dvh - 400px);
    min-height: 240px;
    overflow-y: auto;
    padding: 10px 32px;
    z-index: 1;
    width: calc(100% - 5px);
}
.tourTicket {
    display: flex;
    justify-content: space-between;
    border: 2px solid #d9d9d97a;
    border-radius: 4px;
    padding: 16px;
    align-items: center;
    cursor: pointer;
}
.tourTicket:hover {
    background-color: #F3F9FF;
}
.tourTicketActive {
    background-color: #F3F9FF;
    border: 2px solid #005BA5;
}
.tourTicketInvalid {
    border: 2px solid rgb(247, 156, 156);
}
.ticketInforWrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.inforColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    min-width: 100px;
}
.busColumn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.inforWithIcon {
    display: flex;
    gap: 6px;
    align-items: center;
}
.inforIcon {
    font-size: 16px;
}
.priceWrapper {
    display: flex;
    justify-content: space-between;
    padding-inline: 16px;
}
.priceLabel {
    font-weight: 700;
    font-size: 20px;
}
.checkoutBtn {
    font-weight: 600;
    background-color: rgba(0, 91, 165, 1);
    color: white;
    min-height: 40px;
    border: none;
}
.checkoutBtn:hover {
    color: white !important;
    background-color: rgb(2, 111, 201);
}
/* ticket card */
.ticketCard {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    min-height: 740px;
    width: 410px;
    position: relative;
    z-index: 2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 2px solid #005BA5;
    background-color: white;
}
.ticketCard_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F9FF;
}
.ticketId {
    padding: 15px 20px;
    font-size: 16px;
}
.inforWrapper {
    background-color: white;
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
    min-height: 200px;
}
.loadingOverlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.13);
    z-index: 2;
}
.scrollContainer {
    padding: 0px 12px;
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    /* width: calc(100% - 5px); */
    display: flex;
    flex-direction: column;
}
.formTitle {
    margin-top: 0;
    margin-bottom: 5px !important;
    font-weight: 700 !important;
}
.busFormTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.formItem {
    margin-bottom: 4px;
}
.btnContainer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 12px;
}
.profileContainer {
    margin-top: 14px;
}
/* 
billing info */
.billingInfoContainer {
    padding-inline: 1.5rem;
    width: clamp(16rem, 90vw, 90rem);
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 auto;
    height: 0px;
    min-height: 200px;
    width: 100%;
}

/* select */
.selectWrapper, .selectContainer {
    display: flex;
    flex-direction: column;
}
.selectWrapper {
    max-height: 200px;
    overflow-y: auto;
}
.select_btnContainer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
@media screen and (max-width: 1145px){
    .ticketCard {
        width: unset;
        height: max-content;
        max-width: 500px
    }
    .inforWrapper {
        height: max-content;
    }
}
@media screen and (max-width: 520px) {
    .ticketsContainer, .customerContainer {
        max-height: unset;
    }
    .tourList {
        /* max-height: calc(100dvh - 560px); */
        max-height: unset;
    }
    .ticketCard_Container {
        padding-bottom: 50px;
        width: 100%;
    }
    .ticketsWrapper_Header, .tourList {
        padding-inline: 16px;
    }
    .ticketInfo {
        width: 100%;
        justify-content: flex-end;
    }
    .ticketInforWrapper .inforColumn:nth-of-type(2) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .ticketInforWrapper .inforColumn:nth-of-type(2) .inforWithIcon {
        flex: 1
    }
    .tourList {
        padding-right: 4px;
    }
    .tourTickets {
        flex-direction: row;
        overflow-x: auto;
        padding-bottom: 10px;
        gap: 10px;
    }
    .tourTicket {
        min-width: 300px;
        position: relative;
        padding: 8px;
    }
    .busColumn {
        position: absolute;
        right: 14px;
        top: 8px;
    }
    .billingInfoContainer {
        height: unset;
        padding-inline: 0;
    }
}