.container {
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    padding-left: clamp(1.5rem, 1%, 5rem);
    padding-right: .5rem;
    gap: 16px;
}
.wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    flex: 1;
}
.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    /* flex: 1; */
}
.listContainer_header {
    background-color: rgba(227, 239, 252, 0.699);
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
} 
.listWrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.listWrapper_List {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 24px;
}
.listContainer_footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    width: 100%;
}
/* group item */
.groupItem {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    gap: 5px;
}
.groupItem:hover {
    background-color: #fff7e65b;
}
.groupItem.selected {
    background-color: #FFF7E6;
    border: 1px solid #005BA5;
}
.groupItem_Wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.groupName {
    margin-block: 0 !important;
}
.groupItem_Info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.groupItem_Buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    min-width: 130px;
}
/* ticket list */
.searchBox {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    column-gap: 10px;
    width: 100%;
    padding: 4px 12px 5px 12px;
}
.searchBoxItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}
.groupTicketItem {
    padding: 16px;
    display: flex;
    gap: 12px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}
.groupTicketItem_checkBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
}
.groupTicketWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 13px;
}
.groupTicketFullname {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    display: inline-block;
    flex: 1;
    max-width: 50%;
}
.groupTicketInforItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.inforWithIcon {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
    /* max-width: 48%; */
}
.actionsWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.groupSelectDropdown {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    min-width: 115px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.selectContainer {
    padding: .25rem .75rem;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    width: clamp(150px, 80vw, 280px);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.selectContainer .menuItem {
    display: flex;
    flex-direction: row;
    gap: 10;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ticketListContainer {
    height: 100%;
    gap: 10px;
    flex-wrap: wrap;
}
.ticketSelectedItem {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ticketSelectedItem_name {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 1300px) {
    .listContainer_group {
        height: 300px;
        /* max-width: 100%; */
        width: clamp(20rem, 100%, 1100px);
        min-width: 320px !important;
        margin-inline: auto;
    }
    .listWrapper_List_group {
        flex-direction: row;
    }
    .ticketListContainer {
        width: clamp(320px, 100%, 1100px) !important;
        max-width: 1100px !important;
        min-width: 320px;
        margin-inline: auto;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 2.5rem 0 0;
    }
}