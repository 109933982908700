.docker-group-0 {
    background-color: #ffffff !important;
  }
  .docker-group-1 {
    background-color: #ccffcc !important;
  }
  .docker-group-2 {
    background-color: #ccccff !important;
  }
  .docker-group-3 {
    background-color: #ffffcc !important;
  }
  .docker-group-4 {
    background-color: #ffccff !important;
  }
  .docker-group-5 {
    background-color: #ccffff !important;
  }
  